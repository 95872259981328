#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
  zoom: 1;
}
html.js input.form-autocomplete {
  background-position: 100% center;
  background-repeat: no-repeat;
}
html.js input.throbbing {
  background-position: 100% center;
}
html.js fieldset.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}
html.js fieldset.collapsed .fieldset-wrapper {
  display: none;
}
fieldset.collapsible {
  position: relative;
}
fieldset.collapsible .fieldset-legend {
  display: block;
}
.form-textarea-wrapper textarea {
  display: block;
  margin: 0;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.resizable-textarea .grippie {
  border: 1px solid #ddd;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}
body.drag {
  cursor: move;
}
.draggable a.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
}
a.tabledrag-handle:hover {
  text-decoration: none;
}
a.tabledrag-handle .handle {
  height: 13px;
  margin: -0.4em 0.5em;
  padding: 0.42em 0.5em;
  width: 13px;
}
a.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}
div.indentation {
  float: left;
  height: 1.7em;
  margin: -0.4em 0.2em -0.4em -0.4em;
  padding: 0.42em 0 0.42em 0.6em;
  width: 20px;
}
div.tree-child {
}
div.tree-child-last {
}
div.tree-child-horizontal {
}
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}
table.sticky-header {
  background-color: #fff;
  margin-top: 0;
}
.progress .bar {
  background-color: #fff;
  border: 1px solid;
}
.progress .filled {
  background-color: #000;
  height: 1.5em;
  width: 5px;
}
.progress .percentage {
  float: right;
}
.ajax-progress {
  display: inline-block;
}
.ajax-progress .throbber {
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}
.ajax-progress .message {
  padding-left: 20px;
}
tr .ajax-progress .throbber {
  margin: 0 2px;
}
.ajax-progress-bar {
  width: 16em;
}
.container-inline div,
.container-inline label {
  display: inline;
}
.container-inline .fieldset-wrapper {
  display: block;
}
.nowrap {
  white-space: nowrap;
}
html.js .js-hide {
  display: none;
}
.element-hidden {
  display: none;
}
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
* html .clearfix {
  height: 1%;
}
*:first-child + html .clearfix {
  min-height: 1%;
}
